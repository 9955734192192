<template>
  <div class="row">
    <div class="col-md-12" v-if="Object.keys(admin).length !== 0">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="hubs" class="col-form-label col-form-label-sm">Hubs <span class="custom-required">*</span></label>
                <v-select v-model="admin_hubs" multiple :class="errors.first('hubs') ? 'borderColor' : ''" name="hubs" id="hubs" v-validate="'required'" label="name" :options="hubs" placeholder="Select a hub"></v-select>
                <div v-if="errors.first('hubs')" class="error">Hubs is required</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <a-button type="primary" class="text-center mr-2" :loading="loader" @click.prevent="save">Assign Hub</a-button>
                <a-button type="danger" class="text-center mr-2" @click="$router.go(-1)">Back</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-else>
      <div class="card">
        <div class="card-body">
          <h5 class="text-center text-secondary">{{ message }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'hub_assign_to_admin',
  data() {
    return {
      admin: {},
      admin_hubs: [],
      hubs: [],
      validation_errors: {},
      show: false,
      loader: false,
      message: null,
    }
  },
  mounted() {
    this.getAdminHubs()
  },
  methods: {
    getAdminHubs() {
      const userId = this.$route.params.user_id
      apiClient.get('api/admin-scenario/admin-hubs/' + userId)
        .then((response) => {
          if (response.data.error === true) {
            this.message = response.data.message
          } else {
            this.admin = response.data.admin
            this.admin_hubs = response.data.admin.admin_hubs
            this.hubs = response.data.hubs
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/admin-scenario/admin-hubs/store', { admin_hubs: this.admin_hubs, user_id: this.$route.params.user_id })
            .then((response) => {
              this.loader = false
              this.$notification.success({
                message: response.data.message,
              })
              this.$router.push({ name: 'adminScenarioAdmins' })
            })
            .catch(error => {
              this.loader = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
.error {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor {
  border: 1px solid red !important;
  border-radius: 4px !important;
}
</style>
